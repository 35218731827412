import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TagsMasonry from "../components/tags/tagsMasonry"

export default ({
  data: {
    contentfulLayoutTemplate: {
      title,
      seoTitle,
      shortDescription,
      slug,
      slugModifier,
      canonical,
    },
    allContentfulTag: { edges: tags },
  },
  location,
}) => {
  return (
    <Layout location={location} pageTitle={title}>
      <SEO
        title={seoTitle}
        description={shortDescription}
        pathname={slug}
        canonical={canonical}
        robots={`noindex, follow`}
      />
      <div className="container mx-auto px-6">
        <h1 className="text-4xl font-bold mt-6 mb-10">{title}</h1>
        <TagsMasonry content={tags} slugModifier={slugModifier} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query allTagsQuery {
    contentfulLayoutTemplate(title: { eq: "Tags" }) {
      title
      seoTitle
      shortDescription
      slug
      slugModifier
      canonical
    }
    allContentfulTag(sort: { fields: slug, order: ASC }) {
      edges {
        node {
          id
          title
          slug
          canonical
          shortDescription
          seoTitle
        }
      }
    }
  }
`
