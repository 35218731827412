import React, { Fragment } from "react"
import { Link } from "gatsby"
import Masonry from "react-masonry-css"

const TagsMasonry = ({ content, slugModifier }) => {
  return (
    <Masonry
      className="flex -mx-2"
      columnClassName="px-2"
      breakpointCols={{
        default: 5,
        640: 1,
        768: 2,
        1024: 3,
        1280: 4,
      }}
    >
      {content.map(({ node: { id, title, slug } }) => {
        return (
          <Fragment key={id}>
            <Link
              to={`/${
                slugModifier !== null && slugModifier !== undefined
                  ? slugModifier + "/"
                  : ""
              }${slug}/`}
            >
              <div className="rounded overflow-hidden shadow-lg hover:shadow-2xl mb-10 md:mb-4">
                <div className="px-6 py-4">
                  <div className="text-center font-bold text-2xl mb-2">
                    {title}
                  </div>
                </div>
              </div>
            </Link>
          </Fragment>
        )
      })}
    </Masonry>
  )
}

export default TagsMasonry
